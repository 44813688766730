import { readCurrentUniverse } from '@/modules/match/store/getters'
import i18n from '@/plugins/i18n'
import store from '@/store'

export const clockToFormatDate = (clock: number, format: string): string => {
  const currentUniverse = readCurrentUniverse(store)
  if (currentUniverse === null) return 'RELOAD_PAGE'
  // console.log(currentUniverse.start_date)
  const numberOfMlSeconds = new Date(currentUniverse.start_date).getTime()
  const diff = clock * 5 * 60 * 1000
  const clockDate = new Date(numberOfMlSeconds + diff)
  return i18n.d(clockDate, format)
}

export const clockToDate = (clock: number): Date => {
  const currentUniverse = readCurrentUniverse(store)
  if (currentUniverse === null) return new Date()
  const numberOfMlSeconds = new Date(currentUniverse.start_date).getTime()
  const diff = clock * 5 * 60 * 1000
  return new Date(numberOfMlSeconds + diff)
}

export const dateToClock = (date: Date): number => {
  // debugger
  const currentUniverse = readCurrentUniverse(store)
  if (currentUniverse === null) return -1
  // console.log(currentUniverse.start_date)
  const epocStart = new Date(currentUniverse.start_date).getTime()
  const epocTarget = date.getTime()
  const diff = epocTarget - epocStart
  return diff / 1000 / 60 / 5
}

export const clockToEpoc = (clock: number): number => {
  const currentUniverse = readCurrentUniverse(store)
  if (currentUniverse === null) return -1
  const numberOfMlSeconds = new Date(currentUniverse.start_date).getTime()
  const diff = clock * 5 * 60 * 1000
  return numberOfMlSeconds + diff
}
