
import Vue from 'vue'
import { IHeaderOption, KwikMediumContainer, KwikPageToolbar, KwikTableAction, KwikDialog, IPagingInput, ISortingInput } from 'kwik-vue'
// import { readClock, readCurrentMatch } from '@/modules/match/store/getters'
import { clockToFormatDate } from '@/utils/clockManager'
import { commitKwikAddNotification } from 'kwik-vue/store/main/mutations'
import { ICompanyOffering, IOfferingGetInput } from '@/modules/offering/types'
import { readOfferingsCount, readOfferingsCurrentPage } from '@/modules/offering/store/getters'
import { dispatchGetOfferingsCurrentPage } from '@/modules/offering/store/actions'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils'
import { IBatch } from '@/modules/warehouse/types'
import { dispatchGetBatchesForShipping, dispatchShip } from '../store/actions'
import { IBatchesForShippingGetInput, IBatchToShipItem, IShipBatchInput, IShipInput } from '../types'
import { RouteNames } from '@/router/names'

export default Vue.extend({
  name: 'FFNewCustomerTransaction',
  components: { KwikMediumContainer, KwikPageToolbar, KwikDialog, KwikTableAction },
  data () {
    return {
      options: {} as any,
      loading: true,
      selectedOffer: null as ICompanyOffering | null,
      showSelectOffer: false,
      loadedQuantity: 0,
      targetQuantity: 0,
      validBatches: [] as IBatchToShipItem[],
      batches: [] as IShipBatchInput[],
      showCommit: false,
      currentCommitBatch: null as IBatchToShipItem | null,
      currentQuantity: 0,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Spedizione merce', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Cliente',
          sortable: true,
          value: 'customer.business_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Prodotto',
          sortable: true,
          value: 'customer_request.product.name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Stato',
          sortable: true,
          value: 'status',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità',
          sortable: true,
          value: 'quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Prezzo unitario',
          sortable: true,
          value: 'unit_price',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di consegna',
          sortable: true,
          value: 'due_date',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di scadenza',
          sortable: true,
          value: 'product_expiration',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    batchesHeaders (): IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Codice',
          sortable: true,
          value: 'batch_code',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Scadenza',
          sortable: true,
          value: 'expiration_clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Disponibile',
          sortable: true,
          value: 'available',
          hidden: false,
          align: 'start'
        },
        {
          text: 'In consegna',
          sortable: true,
          value: 'total_quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    offeringsItems () {
      return readOfferingsCurrentPage(this.$store)
    },
    total () {
      return readOfferingsCount(this.$store)
    },
    canShip () {
      if (this.selectedOffer === null) return false
      if (this.targetQuantity !== this.loadedQuantity) return false
      return true
    }
  },
  methods: {
    async cancel () {
      await this.$router.push(
        {
          name: RouteNames.FF_CUSTOMER_TRANSACTIONS
        }
      )
    },
    async confirm () {
      if (!this.selectedOffer) {
        commitKwikAddNotification(this.$store, {
          content: 'Seleziona l\' offerta da completare',
          color: 'error'
        })
        return
      }

      const payload: IShipInput = {
        company_offering_id: this.selectedOffer.id,
        batches: this.batches
      }
      await dispatchShip(this.$store, payload)
      commitKwikAddNotification(this.$store, {
        content: 'Spedizione confermata',
        color: 'success'
      })
      await this.$router.push(
        {
          name: RouteNames.FF_CUSTOMER_TRANSACTIONS
        }
      )
    },
    async startSelectOffer () {
      await this.loadPage()
      this.showSelectOffer = true
    },
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      const payload: IOfferingGetInput = {
        paging: paging,
        sorting: sorting,
        customer_id: null,
        status: 'WON'
      }
      await dispatchGetOfferingsCurrentPage(this.$store, payload)
      this.loading = false
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    selectOffer (offer: ICompanyOffering) {
      this.selectedOffer = offer
      this.targetQuantity = this.selectedOffer.quantity
      this.loadBatches()
      this.showSelectOffer = false
    },
    async loadBatches () {
      if (this.selectedOffer === null) return
      const payload: IBatchesForShippingGetInput = {
        product_id: this.selectedOffer.customer_request.product.id,
        expiration_clock: this.selectedOffer.product_expiration
      }
      const batches: IBatch[] = await dispatchGetBatchesForShipping(this.$store, payload)
      for (const batch of batches) {
        const candidateBatch: IBatchToShipItem = {
          batch_id: batch.id,
          batch_code: batch.code,
          used_quantity: batch.used_quantity,
          total_quantity: batch.total_quantity,
          ship_quantity: 0,
          expiration_clock: batch.expiration_clock
        }
        this.validBatches.push(candidateBatch)
      }
    },
    openBatchActions (batch: IBatchToShipItem) {
      this.currentCommitBatch = batch
      this.currentQuantity = 0
      this.showCommit = true
    },
    commitQuantity () {
      debugger
      if (!this.currentCommitBatch) return
      if (+this.currentQuantity <= 0) {
        this.closeCommitQuantity()
        return
      }

      // controllo che il lotto abbia quantità disponibili
      const usedQuantity = this.currentCommitBatch.used_quantity + this.currentCommitBatch.ship_quantity
      const availableQuantity = this.currentCommitBatch.total_quantity - usedQuantity
      if (+this.currentQuantity > availableQuantity) {
        commitKwikAddNotification(this.$store, {
          content: 'Quantità NON valide',
          color: 'error'
        })
        this.closeCommitQuantity()
        return
      }
      console.log(this.currentCommitBatch)
      const batchToShip: IShipBatchInput = {
        batch_id: this.currentCommitBatch.batch_id,
        quantity: +this.currentQuantity
      }
      this.addOrUpdateShipBatch(batchToShip)
      this.loadedQuantity = this.loadedQuantity + +this.currentQuantity

      const batchForProduction = this.validBatches.find(e => e.batch_code === this.currentCommitBatch?.batch_code)
      if (!batchForProduction) return
      batchForProduction.ship_quantity += +this.currentQuantity

      this.closeCommitQuantity()
    },
    closeCommitQuantity () {
      this.currentCommitBatch = null
      this.currentQuantity = 0
      this.showCommit = false
    },
    addOrUpdateShipBatch (newBatch: IShipBatchInput): void {
      const index = this.batches.findIndex(batch => batch.batch_id === newBatch.batch_id)
      if (index !== -1) {
        this.batches[index].quantity += newBatch.quantity
      } else {
        this.batches.push(newBatch)
      }
    },
    reset () {
      this.selectedOffer = null
      this.showSelectOffer = false
      this.loadedQuantity = 0
      this.targetQuantity = 0
      this.validBatches = []
      this.batches = []
      this.currentCommitBatch = null
      this.currentQuantity = 0
      this.showCommit = false
    }
  }
})
